html { 
}

body { 
    margin-top: 0 !important;
}

h1, h2, h3, h4, h5, h6 { 
    font-family: 'Open Sans';
}

a {
    /*color: #0083e8;*/
}

b, strong { 
}
 
.clr {
  clear: both;
  width: 100%;
}

/* Style for Logo Image - Starts */
.logo-img {
    height:var(--header-logo);
    display: flex;
    align-items:center
}

.hp-logo-black {
    max-height:55px
}
/* Style for Logo Image - Ends */

.announcement {
    color: #ffffff;
    position: static !important;
}
.announcement__exit path {
    fill: #ffffff;
}
.announcement {
    background: #e36a5b;
}

.header-holder {
    position: static !important; 
}

.header { 
    min-height: 100px;
}

.header .logo a { 
    min-height: 100px;
}

.header .logo img {
    max-height: 80px !important;
}
header.header-holder {
  padding: 14px 0 !important;
}
.sdc-wide-banner {
    width: 100%;
    /*padding: 32px;*/
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
}

#shopify-section-1620411854e404eaf1 {
    /*background: #fff;*/
    padding-bottom: 120px !important;
    margin-bottom: 0;
}

#shopify-section-1620411854e404eaf1 { 
  padding-bottom: 0;
}
h5.footer-item__title {
   font-family: 'Open Sans', sans-serif;
   font-size: 0.8em;
}

.hp-heading1 p { 
    font-size: 1em !important;
    line-height: 1.4 !important; 
}

.hp-statistics p { 
    font-size: 1em !important;
    line-height: 1.4 !important;
    letter-spacing: 1px;
    font-family: 'Gotham Light', sans-serif;
    max-width: 450px;
    margin-bottom: 50px;
}

form.contact-form input  {
    border: 2px solid #0a0a0a;
    /*background: #edece8;*/
}
form.contact-form select {
    border: 2px solid #0a0a0a;
    -moz-appearance: button;
    -webkit-appearance: button;
    appearance: button;
}
form.contact-form button {
    background: #0a0a0a;
}
form.contact-form button svg {
    fill: #fff;
}

.contact-form button.button:hover {
    background: #000;
}

#shopify-section-3e9b01f1-3658-4172-84db-a3e08edac2c0 .rich-text__content p {
    font-size: 4.1em !important;
    font-family: 'Open Sans', sans-serif;
    letter-spacing: 1px;
    font-weight: bold;
    line-height: 1.2em !important;
    margin-bottom: 70px; 
}

#shopify-section-3e9b01f1-3658-4172-84db-a3e08edac2c0 span.section-header__title.h4 {
    font-family: 'Open Sans', sans-serif;
}

.sdc-wide-banner-desktop {
    display: block;
}
.sdc-wide-banner-mobile {
    display: none;
}

@media (max-width: 700px) {
    #shopify-section-3e9b01f1-3658-4172-84db-a3e08edac2c0 {
        padding-left: 20px;
        padding-right: 20px;
    }
    
    #shopify-section-3e9b01f1-3658-4172-84db-a3e08edac2c0 .rich-text__content p {
        font-size: 30px !important;
        margin-bottom: 40px !important;
    }
    .sdc-wide-banner-desktop {
        display: none;
    }
    .sdc-wide-banner-mobile {
        display: block;
    }
}


